import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CallState,
  Experiment,
  ExperimentState,
  ExperimentVariant,
} from "redmond";
import { fetchActiveExperiments } from "../api/v0/experiments/fetchExperiments";
import {
  AnyExperimentDef,
  experimentDef,
  ExperimentVariants,
} from "./experiment-def";

// Active Experiments

export const HOTEL_ROOMPICKER_REDESIGN = "c1-marketplace-roompicker-redesign";
export const TREES_MODAL_EXPERIMENT = "c1-marketplace-trees";
export const TRAVEL_WALLET_OFFER_EXPERIMENT =
  "c1-marketplace-travel-wallet-offers";
export const CORP_DEBUGGING_PANEL = "corp-dynamic-limits";
export const CASH_VALUEPROP_EXPERIMENT = "c1-marketplace-cash-valueprop-merch";
export const SIMILAR_HOTELS_EXPERIMENT = "c1-marketplace-similar-hotels";
export const TRAVEL_WALLET_CREDITS_EXPERIMENT =
  "c1-marketplace-travel-wallet-credits";
export const PRICE_MATCH_CREDITS_EXPERIMENT =
  "c1-marketplace-price-match-credits";
export const PREFERRED_HOTEL_EXPERIMENT =
  "c1-marketplace-cot-preferred-hotel-merch";
export const HOTEL_INFO_CHECKOUT_UPDATE_EXPERIMENT =
  "c1-marketplace-hotel-info-checkout-update";
export const MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT =
  "c1-marketplace-mobile-homescreen-redesign";
export const PREMIER_COLLECTION_EXPERIMENT =
  "c1-marketplace-luxury-hotels-collection";
export const HOTELS_SEARCH_DISTANCE_FROM_NEARBY_EXPERIMENT =
  "c1-marketplace-search-distance-from-nearby";
export const HOTELS_SHOP_IMAGE_MODAL = "c1-marketplace-hotel-shop-image-modal";
export const VCN_ENABLEMENT = "c1-marketplace-vcn-enablement";
export const HOTELS_CFAR = "c1-fintech-hotel-cfar";
export const HOTEL_CFAR_REFUND_DISPLAY = "c1-fintech-hotel-cfar-refund-display";
export const HOTELS_CFAR_ELIGIBLE_POLICY =
  "c1-fintech-hotel-cfar-eligible-policy";
export const HOTELS_LFAR = "c1-fintech-LFAR-hotels";
export const HOTELS_PRICE_FREEZE = "c1-fintech-pf-hotel";
export const CREDIT_OFFER_STACKING_V1 =
  "c1-marketplace-credit-offer-stacking-v1";
export const TRAVEL_CREDIT_HISTORY_EXPERIMENT =
  "c1-marketplace-credit-transaction-history";
export const RECENTLY_VIEWED_MASTER_V1 = "c1-marketplace-recently-viewed_v1";
export const DESKTOP_RECENTLY_VIEWED_HOTELS =
  "c1-marketplace-recently-viewed-desktop-p0";
export const MOBILE_RECENTLY_VIEWED_HOTELS =
  "c1-marketplace-recently-viewed-mobile-p0";
export const ANNUAL_TRAVEL_CREDITS = "c1-marketplace-annual-travel-credits";
export const LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT =
  "c1-marketplace-lifestyle-for-premium-cardholders";
export const LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT =
  "c1-marketplace-lifestyle-for-non-premium-cardholders";
export const HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS =
  "c1-fintech-pf-hotel-refundable-rooms";
export const FINTECH_HOTEL_UX_UPDATED = "c1-fintech-hotel-UX-updated";
export const HOTEL_PRICE_DROP_SMOKE_TEST = "cap1-fintech-hotel-pd-smoke-test";
export const HOTEL_PD_BANNER_COPY = "c1-fintech-hotel-pd-banner";
export const HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES =
  "c1-fintech-hotel-cfar-rr-multiple-cancellation-policies";
export const TRAVEL_SALE = "c1-marketplace-travel-sale";
export const HOTEL_DISPLAY_ROOM_PRICING_UPDATE =
  "c1-fintech-hotel-display-room-pricing-update";
export const HOTEL_CFAR_MODEL_V1 = "c1-fintech-hotel-cfar-model-v1";

export const HOTEL_COLOR_CALENDAR =
  "c1-marketplace-hotel-colored-pricing-calendar";

export const HOTEL_CFAR_ROOM_ID_MATCH = "c1-fintech-hotel-cfar-room-id-match";
export const HOTEL_CFAR_PASS_OPAQUE_PARAM =
  "c1-fintech-hotel-cfar-pass-opaque-param";

export const CUSTOMER_PROFILE_EXPERIMENT = "c1-marketplace-customer-profile";

export const HOTELS_TAXES_AND_FEES_EXPERIMENT =
  "c1-marketplace-HotelsCaliforniaBill537";

export const GLOBAL_MOBILE_NAV_EXPERIMENT = "c1-marketplace-global-mobile-nav";

export const HOTELS_CALIFORNIA_BILL_644_EXPERIMENT =
  "c1-marketplace-HotelsCaliforniaBill644";

export const STAYS_HOMEPAGE = "c1-stays-homepage";
export const STAYS_SEARCH = "c1-stays-search";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";
export const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
export type DefaultVariantType = typeof CONTROL | typeof AVAILABLE;
export const VARIANT_1 = "variant-1";
export const VARIANT_2 = "variant-2";

// JSON Response type
// right now typing the response of this experiment: https://app.launchdarkly.com/capital-one/test/features/c1-fintech-hotel-cfar-model-v1/variations
// as this looks like the only JSON experiment that we are consuming.
// if this needs to be generalized then we will do so at a later time.
export interface LDJSONConfig {
  data: {
    model: string;
    version: string;
  };
  variantName: string;
}

// Variants for c1-marketplace-cash-valueprop-merch
export const CASH_VALUEPROP_A = "cash-valueprop-a";
export const CASH_VALUEPROP_B = "cash-valueprop-b";
export const CASH_VALUEPROP_C = "cash-valueprop-c";
export const CASH_VALUEPROP_VARIANTS = [
  CONTROL,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
] as const;
export type CashValuePropVariantType =
  | typeof CONTROL
  | typeof CASH_VALUEPROP_A
  | typeof CASH_VALUEPROP_B
  | typeof CASH_VALUEPROP_C;

// Variants for c1-fintech-hotel-pd-banner
export const HOTEL_PD_BANNER_COPY_PREMIER_ONLY = "premier-only";
export const HOTEL_PD_BANNER_COPY_PREMIER_AND_LIFESTYLE =
  "premier-and-lifestyle";
export const HOTEL_PD_BANNER_COPY_VARIANTS = [
  HOTEL_PD_BANNER_COPY_PREMIER_ONLY,
  HOTEL_PD_BANNER_COPY_PREMIER_AND_LIFESTYLE,
] as const;
export type HotelPDBannerCopyVariantType =
  | typeof HOTEL_PD_BANNER_COPY_PREMIER_ONLY
  | typeof HOTEL_PD_BANNER_COPY_PREMIER_AND_LIFESTYLE;

// Variants for c1-marketplace-similar-hotels
export const SIMILAR_HOTELS_1A = "1A";
export const SIMILAR_HOTELS_1B = "1B";
export const SIMILAR_HOTELS_VARIANTS = [
  CONTROL,
  SIMILAR_HOTELS_1A,
  SIMILAR_HOTELS_1B,
] as const;
export type SimilarHotelsPropVariantType =
  | typeof CONTROL
  | typeof SIMILAR_HOTELS_1A
  | typeof SIMILAR_HOTELS_1B;

// Variants for c1-marketplace-cot-preferred-hotel-merch
export const PREFERRED_HOTELS_V1 = "v1-special-rates";
export const PREFERRED_HOTELS_VARIANT = [CONTROL, PREFERRED_HOTELS_V1] as const;
export type PreferredHotelsPropVariantType =
  | typeof CONTROL
  | typeof PREFERRED_HOTELS_V1;

// Variants for c1-marketplace-mobile-homescreen-redesign
export const MOBILE_HOMESCREEN_REDESIGN_V1 = "variant-1";
export const MOBILE_HOMESCREEN_REDESIGN_V2 = "variant-2";
export const MOBILE_HOMESCREEN_REDESIGN_V3 = "variant-3";
export const MOBILE_HOMESCREEN_REDESIGN_VARIANTS = [
  CONTROL,
  MOBILE_HOMESCREEN_REDESIGN_V1,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
] as const;

export type MobileHomescreenRedesignPropVariantType =
  | typeof CONTROL
  | typeof MOBILE_HOMESCREEN_REDESIGN_V1
  | typeof MOBILE_HOMESCREEN_REDESIGN_V2
  | typeof MOBILE_HOMESCREEN_REDESIGN_V3;

// Variants for c1-marketplace-recently-viewed-desktop-p0
export const DESKTOP_RECENTLY_VIEWED_HOTELS_V1 = "variant-1";
export const DESKTOP_RECENTLY_VIEWED_HOTELS_V2 = "variant-2";
export const DESKTOP_RECENTLY_VIEWED_HOTELS_V3 = "variant-3";
export const DESKTOP_RECENTLY_VIEWED_HOTELS_V4 = "variant-4";
export const DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS = [
  CONTROL,
  DESKTOP_RECENTLY_VIEWED_HOTELS_V1,
  DESKTOP_RECENTLY_VIEWED_HOTELS_V2,
  DESKTOP_RECENTLY_VIEWED_HOTELS_V3,
  DESKTOP_RECENTLY_VIEWED_HOTELS_V4,
] as const;

export type DesktopRecentlyViewedHotelsPropVariantType =
  | typeof CONTROL
  | typeof DESKTOP_RECENTLY_VIEWED_HOTELS_V1
  | typeof DESKTOP_RECENTLY_VIEWED_HOTELS_V2
  | typeof DESKTOP_RECENTLY_VIEWED_HOTELS_V3
  | typeof DESKTOP_RECENTLY_VIEWED_HOTELS_V4;

// Variants for c1-marketplace-recently-viewed-mobile-p0
export const MOBILE_RECENTLY_VIEWED_HOTELS_V1 = "variant-1";
export const MOBILE_RECENTLY_VIEWED_HOTELS_V2 = "variant-2";
export const MOBILE_RECENTLY_VIEWED_HOTELS_V3 = "variant-3";
export const MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS = [
  CONTROL,
  MOBILE_RECENTLY_VIEWED_HOTELS_V1,
  MOBILE_RECENTLY_VIEWED_HOTELS_V2,
  MOBILE_RECENTLY_VIEWED_HOTELS_V3,
] as const;

export type MobileRecentlyViewedHotelsPropVariantType =
  | typeof CONTROL
  | typeof MOBILE_RECENTLY_VIEWED_HOTELS_V1
  | typeof MOBILE_RECENTLY_VIEWED_HOTELS_V2
  | typeof MOBILE_RECENTLY_VIEWED_HOTELS_V3;

interface ExperimentStateWithCallState extends ExperimentState {
  callState: CallState;
}

export const HOTEL_CFAR_REFUND_DISPLAY_CONTROL = "control";
export const HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR =
  "refund-dollar-amount-RR";
export const HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES =
  "refund-dollar-amount-additional-places";
export const HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT =
  "80-percent-refund-amount";
export const HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL = "non-ref-partial";
export const HOTEL_CFAR_REFUND_DISPLAY_VARIANTS = [
  HOTEL_CFAR_REFUND_DISPLAY_CONTROL,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES,
  HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT,
  HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL,
] as const;
export type HotelCfarRefundDisplayVariantType =
  | typeof HOTEL_CFAR_REFUND_DISPLAY_CONTROL
  | typeof HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR
  | typeof HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES
  | typeof HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT
  | typeof HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL;

// Variants for c1-fintech-hotel-cfar-eligible-policy
export const HOTELS_CFAR_ELIGIBLE_POLICY_NON_REFUNDABLE_ONLY =
  "non-refundable-only";
export const HOTELS_CFAR_ELIGIBLE_POLICY_PARTIALLY_REFUNDABLE =
  "partially-refundable";
export const HOTELS_CFAR_ELIGIBLE_POLICY_VARIANTS = [
  HOTELS_CFAR_ELIGIBLE_POLICY_NON_REFUNDABLE_ONLY,
  HOTELS_CFAR_ELIGIBLE_POLICY_PARTIALLY_REFUNDABLE,
] as const;

export type HotelsCfarEligiblePolicyPropVariantType =
  | typeof HOTELS_CFAR_ELIGIBLE_POLICY_NON_REFUNDABLE_ONLY
  | typeof HOTELS_CFAR_ELIGIBLE_POLICY_PARTIALLY_REFUNDABLE;

export const FREE_BREAKFAST_CANCEL = "c1-marketplace-free-breakfast-cancel";
export const VIEW_HOTELS_NEAR = "c1-marketplace-view-hotels-near";
export const LODGING_PROMOTIONS = "HotelPromotion";
export const LODGING_PROMOTIONS_AVAILABLE = "Available";
export type LodgingPromotionsPropVariantType =
  | typeof CONTROL
  | typeof LODGING_PROMOTIONS_AVAILABLE;
export const LODGING_PROMOTIONS_VARIANTS = [
  CONTROL,
  LODGING_PROMOTIONS_AVAILABLE,
] as const;

export const LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS = [
  CONTROL,
  VARIANT_1,
  VARIANT_2,
] as const;

export type LCForPremiumCardholdersPropVariantType =
  | typeof CONTROL
  | typeof VARIANT_1
  | typeof VARIANT_2;

export const LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS = [
  CONTROL,
  VARIANT_1,
  VARIANT_2,
] as const;

export type LCForNonPremiumCardholdersPropVariantType =
  | typeof CONTROL
  | typeof VARIANT_1
  | typeof VARIANT_2;

// Variants for c1-marketplace-travel-sale
export const TRAVEL_SALE_LEAD_UP = "lead-up";
export const TRAVEL_SALE_ACTIVE = "active";
export const TRAVEL_SALE_VARIANTS = [
  CONTROL,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_ACTIVE,
] as const;
export type TravelSalePropVariantType =
  | typeof CONTROL
  | typeof TRAVEL_SALE_LEAD_UP
  | typeof TRAVEL_SALE_ACTIVE;

// variants for c1-fintech-hotel-cfar-model-v1
export const CFAR_MODEL_ADVANCE_DAYS_10_21 = "advanceDays10and21";
export const CFAR_MODEL_RANDOM_PERCENT_16_26 = "randomPercent16to26";
export const CFAR_MODEL_RANDOM_PERCENT_5_30_FR_ELIGIBLE =
  "randomPercent5to30FrEligible";
export const CFAR_MODEL_ADVANCE_STAR_RATING_23_28 =
  "advanceAndStarRating23and28";
export const CFAR_MODEL_ADVANCE_STAR_RATING_23_28_COMPARISON =
  "advanceAndStarRating23and28Comparison";
export const CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE =
  "advAndStarRating23and28FrEligible";
export const CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY =
  "advanceAndStarRating23and28FrEligibleCopy";
export const CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_NEW_UX =
  "advanceAndStarRating23and28FrEligibleNewUx";
export const CFAR_MODEL_STAR_AND_GBV_15_28_FR_ELIGIBLE_NEW_UX =
  "starRatingAndGbv15to28FrEligibleNewUx";
export const CFAR_MODEL_V1_VARIANTS = [
  CFAR_MODEL_ADVANCE_DAYS_10_21,
  CFAR_MODEL_RANDOM_PERCENT_16_26,
  CFAR_MODEL_RANDOM_PERCENT_5_30_FR_ELIGIBLE,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_COMPARISON,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_NEW_UX,
  CFAR_MODEL_STAR_AND_GBV_15_28_FR_ELIGIBLE_NEW_UX,
] as const;
export type CfarModelV1VariantType =
  | typeof CFAR_MODEL_ADVANCE_DAYS_10_21
  | typeof CFAR_MODEL_RANDOM_PERCENT_16_26
  | typeof CFAR_MODEL_RANDOM_PERCENT_5_30_FR_ELIGIBLE
  | typeof CFAR_MODEL_ADVANCE_STAR_RATING_23_28
  | typeof CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE
  | typeof CFAR_MODEL_ADVANCE_STAR_RATING_23_28_COMPARISON
  | typeof CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY
  | typeof CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_NEW_UX
  | typeof CFAR_MODEL_STAR_AND_GBV_15_28_FR_ELIGIBLE_NEW_UX;

// Variants for "c1-marketplace-hotel-colored-pricing-calendar
export const HOTEL_COLOR_CALENDAR_WITH_PRICING = "with-pricing";
export const HOTEL_COLOR_CALENDAR_WITHOUT_PRICING = "without-pricing";
export const HOTEL_COLOR_CALENDAR_VARIANTS = [
  CONTROL,
  HOTEL_COLOR_CALENDAR_WITH_PRICING,
  HOTEL_COLOR_CALENDAR_WITHOUT_PRICING,
] as const;

export type HotelColorCalendarPropVariantType =
  | typeof CONTROL
  | typeof HOTEL_COLOR_CALENDAR_WITH_PRICING
  | typeof HOTEL_COLOR_CALENDAR_WITHOUT_PRICING;

// Variants for "c1-marketplace-boost-premier-collection"
export const boostPremierCollectionExperiment = experimentDef(
  "c1-marketplace-boost-premier-collection",
  CONTROL,
  AVAILABLE,
  "2-boost",
  "2-boost-randomized",
  "3-boost",
  "3-boost-randomized"
);
export type BoostPremierCollectionPropVariantType = ExperimentVariants<
  typeof boostPremierCollectionExperiment
>;

// Variants for c1-marketplace-customer-profile
export const DEFAULT_OFF = "default-off";
export const DEFAULT_ON = "default-on";
export const CUSTOMER_PROFILE_VARIANTS = [
  CONTROL,
  DEFAULT_OFF,
  DEFAULT_ON,
] as const;
export type CustomerProfileVariantType =
  | typeof CONTROL
  | typeof DEFAULT_OFF
  | typeof DEFAULT_ON;

// Variants for c1-marketplace-HotelsCaliforniaBill537
export const LEAST_DISRUPTION = "least-disruption";
export const BEST_COMPLIANCE = "best-compliance";
export const HOTELS_TAXES_AND_FEES_VARIANTS = [
  CONTROL,
  LEAST_DISRUPTION,
  BEST_COMPLIANCE,
] as const;
export type HotelsTaxesAndFeesVariantType =
  | typeof CONTROL
  | typeof LEAST_DISRUPTION
  | typeof BEST_COMPLIANCE;

const defaultInitializer = (): ExperimentStateWithCallState => {
  return {
    experiments: [],
    trackingProperties: undefined,
    callState: CallState.NotCalled,
  };
};

export const ExperimentsContext = createContext<
  ExperimentStateWithCallState | undefined
>(undefined);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => exp.id === experimentId);

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentDefVariant<Def extends AnyExperimentDef>(
  experiments: Array<Experiment>,
  def: Def
): ExperimentVariants<Def> {
  const experiment = experiments?.find((exp) => exp.id === def.name);
  if (experiment && def.variants.includes(experiment.variant)) {
    return experiment.variant;
  }
  return def.variants[0];
}

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: string
): DefaultVariantType {
  return getExperimentVariantCustomVariants(
    experiments,
    experimentId,
    DEFAULT_VARIANTS
  );
}

export function getExperimentVariantJSON(
  experiments: Array<Experiment>,
  experimentId: string
): LDJSONConfig | null {
  const experiment = experiments.find((exp) => exp.id === experimentId);
  if (!experiment) {
    return null;
  }
  return JSON.parse(experiment.variant);
}

export function useExperiments(): ExperimentStateWithCallState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function useExperiment(
  experimentId: string,
  target: string = ExperimentVariant.AVAILABLE
) {
  const { experiments } = useExperiments();
  if (!experiments) throw new Error("No experiments found in Context");
  const expVariant = getExperimentVariant(experiments, experimentId);

  return expVariant === target;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

const ExperimentsProvider: FC<
  PropsWithChildren<{ initState?: ExperimentStateWithCallState }>
> = ({ initState = defaultInitializer(), children }) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        setState({ ...state, callState: CallState.InProcess });
        await fetchActiveExperiments().then((result) => {
          setState({
            ...(result as ExperimentState),
            callState: CallState.Success,
          });
        });
      };
      fetchExperiments();
    }
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
