import { getConfigTenant } from "@capone/common";

const urlParams = new URLSearchParams(window.location.search);
const isAgentQuery = urlParams.get("agent") === "true";
const isAgentURL = window.location.href.includes("agent");
const isAgentPortal = isAgentURL || isAgentQuery;

const config = {
  ptFlightsModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_FLIGHTS_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_FLIGHTS_MODULE_URL,
  ptHotelsModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_HOTELS_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_HOTELS_MODULE_URL,
  ptTripsModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_TRIPS_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_TRIPS_MODULE_URL,
  ptTermsModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_TERMS_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_TERMS_MODULE_URL,
  ptCarsModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_CARS_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_CARS_MODULE_URL,
  ptPremierCollectionModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_PREMIER_COLLECTION_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_PREMIER_COLLECTION_MODULE_URL,
  ptVacationRentalsModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_VACATION_RENTALS_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_VACATION_RENTALS_MODULE_URL,
  ptExchangeModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_EXCHANGE_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_EXCHANGE_MODULE_URL,
  ptTravelWalletModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_TRAVEL_WALLET_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_TRAVEL_WALLET_MODULE_URL,
  ptCustomerProfileModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_CUSTOMER_PROFILE_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_CUSTOMER_PROFILE_MODULE_URL,
  ptPackagesModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${window.__mclean_env__.REACT_APP_PAWTUCKET_PACKAGES_MODULE_URL}`
    : process.env.REACT_APP_PAWTUCKET_PACKAGES_MODULE_URL,
  googleMapsApiKey: window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_GOOGLE_MAPS_API_KEY
    : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  ptAuthModuleUrl: window.__mclean_env__
    ? `${window.location.origin}${
        isAgentPortal
          ? window.__mclean_env__.REACT_APP_PAWTUCKET_AGENT_AUTH_MODULE_URL
          : window.__mclean_env__.REACT_APP_PAWTUCKET_AUTH_MODULE_URL
      }`
    : isAgentPortal
    ? process.env.REACT_APP_PAWTUCKET_AGENT_AUTH_MODULE_URL
    : process.env.REACT_APP_PAWTUCKET_AUTH_MODULE_URL,
  ptIsAgentPortal: isAgentPortal,
  ENV: window.__mclean_env__ ? window.__mclean_env__.ENV : "",
  REACT_APP_VERSION: window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_VERSION
    : "",
  isMaintenanceModeEnabled: window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_MAINTENANCE_MODE_ENABLED
    : "false",
  isTimedMaintenanceModeEnabled: window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_TIMED_MAINTENANCE_MODE_ENABLED
    : "false",
  MAINTENANCE_START_TIME: window.__mclean_env__
    ?.REACT_APP_TIMED_MAINTENANCE_MODE_START_TIME
    ? parseInt(
        window.__mclean_env__.REACT_APP_TIMED_MAINTENANCE_MODE_START_TIME
      )
    : undefined,
  MAINTENANCE_END_TIME: window.__mclean_env__
    ?.REACT_APP_TIMED_MAINTENANCE_MODE_END_TIME
    ? parseInt(window.__mclean_env__.REACT_APP_TIMED_MAINTENANCE_MODE_END_TIME)
    : undefined,
  MAINTENANCE_TITLE: window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_MAINTENANCE_TITLE
    : "",
  MAINTENANCE_SUBTITLE: window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_MAINTENANCE_SUBTITLE
    : "",
  CASH_CARD_MAINTENANCE_ICON: window.__mclean_env__
    ? window.__mclean_env__.CASH_CARD_MAINTENANCE_ICON
    : "false",
  corporatePortalUrl: window.__mclean_env__
    ? window.__mclean_env__.CORPORATE_PORTAL_URL
    : process.env.CORPORATE_PORTAL_URL,
  CFAR_DISCOUNT_EXPIRATION_DATE_MILLIS: window.__mclean_env__
    ? window.__mclean_env__.CFAR_DISCOUNT_EXPIRATION_DATE_MILLIS
    : "",
  TRAVEL_SALE_FEATURED_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_FEATURED_DESTINATIONS || [],
  TRAVEL_SALE_SORTED_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_SORTED_DESTINATIONS || [],
  TRAVEL_SALE_INTERNATIONAL_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_INTERNATIONAL_DESTINATIONS || [],
  TRAVEL_SALE_US_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_US_DESTINATIONS || [],
  TRAVEL_SALE_BEACH_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_BEACH_DESTINATIONS || [],
  TRAVEL_SALE_WINTER_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_WINTER_DESTINATIONS || [],
  TRAVEL_SALE_SEARCH_CONTINENTS:
    window.__mclean_env__.TRAVEL_SALE_SEARCH_CONTINENTS || {},
  TRAVEL_SALE_SOLD_OUT_HOTEL_DESTINATIONS:
    window.__mclean_env__.TRAVEL_SALE_SOLD_OUT_HOTEL_DESTINATIONS || [],
  TENANT: getConfigTenant(),
};

export default config;
